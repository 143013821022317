import { Box, Divider, Fade, Heading, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { ColoredText } from "../ColoredText"

const headings = [
  // <VStack spacing={2} alignItems="stretch">
  //   <Heading fontWeight="extrabold" size="2xl">
  //     Great basketball players practice free throws.
  //   </Heading>
  //   <Text fontSize="xl" opacity={0.6}>Great writers and programmers do too.</Text>
  // </VStack>,
  <VStack spacing={2} alignItems="stretch">
    <Heading fontWeight="extrabold" size="2xl">
      Typing speed is the ultimate productivity hack.
    </Heading>
    <Text fontSize="xl" opacity={0.6}>
      ...and the best way to improve speed is to improve accuracy.
    </Text>
  </VStack>,
  // <VStack spacing={2} alignItems="stretch">
  //   <Heading fontWeight="extrabold" size="2xl">
  //     Type at the speed of thought.
  //   </Heading>
  //   <Text fontSize="xl" opacity={0.6}>
  //     The faster you type, the more you can get done, the more free time you'll
  //     have.
  //   </Text>
  // </VStack>,
  // <VStack spacing={2} alignItems="stretch">
  //   <Heading fontWeight="extrabold" size="2xl">
  //     Great musicians don't make mistakes while they play.
  //   </Heading>
  //   <Text fontSize="xl" opacity={0.6}>
  //     Many of us have become typists by necessity. We never learned typing as a
  //     craft.
  //   </Text>
  // </VStack>,
  // <VStack spacing={2} alignItems="stretch">
  //   <Heading fontWeight="extrabold" size="2xl">
  //     You're a typo away from looking unprofessional.
  //   </Heading>
  //   <Text fontSize="xl" opacity={0.6}>More accurate typing means less proofreading.</Text>
  // </VStack>,
  <VStack spacing={2} alignItems="stretch">
    <Heading fontWeight="extrabold" size="2xl" maxW="20ch">
      ☕️ is to 🧠 as WPM is to&nbsp;productivity.
    </Heading>
    <Text fontSize="xl" opacity={0.6}>
      Stretch your fingers with some typing practice before you start your day.
    </Text>
  </VStack>,
  <VStack spacing={2} alignItems="stretch">
    <Heading fontWeight="extrabold" size="2xl" maxW="20ch">
      The 🗝 to your success isn't just one key, it's an entire ⌨️.
    </Heading>
    <Text fontSize="xl" opacity={0.6}>
      A faster speed improves everything else you do.
    </Text>
  </VStack>,
  // <div>
  //   <Heading fontWeight="extrabold" size="2xl">
  //     😅: 💡 → 📈
  //   </Heading>
  //   <Heading fontWeight="extrabold" size="2xl">
  //     🤑: 💡 → ⌨️ → 📈
  //   </Heading>
  //   <Text fontSize="xl" opacity={0.6}>
  //     The thing that stands between you and your ideas is a keyboard.
  //   </Text>
  // </div>,
  // <VStack spacing={2} alignItems="stretch">
  //   <Heading fontWeight="extrabold" size="xl">
  //     Don't wait for Neuralink before you can type as fast as you can think.
  //   </Heading>
  //   <Text fontSize="xl" opacity={0.6}>
  //     Faster WPM will tide you over until Elon Musk can put a chip in your
  //     skull.
  //   </Text>
  // </VStack>,
  <VStack spacing={2} alignItems="stretch">
    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
    <Heading fontWeight="extrabold" size="2xl" maxW="20ch">
      You're flawless 💅 in every&nbsp;way except{" "}
      <ColoredText display="inline" colorScheme="red">
        on
      </ColoredText>
      .
    </Heading>
    <Text fontSize="xl" opacity={0.6}>
      Typing well isn't just about typing fast.
    </Text>
  </VStack>,
  <VStack spacing={2} alignItems="stretch">
    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
    <Heading fontWeight="extrabold" size="2xl">
      You have a good 🧠, but can your typing speed keep up?
    </Heading>
    <Text fontSize="xl" opacity={0.6}>
      The key to fast speed is accuracy, and the key to accuracy is practice.
    </Text>
  </VStack>,
]

export function LoggedOutHeader() {
  return (
    <VStack
      spacing={2}
      // mt={4}
      alignItems="start"
    >
      {headings[Math.floor(Math.random() * headings.length)]}
      {/* <Heading fontWeight="extrabold" size="xl" maxWidth="26ch">
            You have a good 🧠, but can your typing speed keep up?
          </Heading>
          <Text fontSize="xl" opacity={0.6}>
            The key to fast speed is accuracy, and the key to accuracy is
            practice.
          </Text> */}
    </VStack>
  )
}
