import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react"
import { head, tail } from "lodash-es"
import styled from "@emotion/styled"
import React, { FC, forwardRef, useEffect, useRef } from "react"
import { usePrefs } from "../typing-test-2/PrefsContext"

const commonWordProps: BoxProps = {
  borderRadius: "md",
  paddingY: ".1em",
  paddingX: ".2em",
}

const TypedWord: FC<{ wanted: string; actual: string }> = ({
  wanted,
  actual,
}) => {
  const { prefs } = usePrefs()
  const correctColor = useColorModeValue("gray.400", "gray.500")
  const wrongColor = useColorModeValue("red.600", "red.200")
  const statusColor = wanted === actual ? correctColor : wrongColor

  const color = prefs.showErrorsWhileTyping ? statusColor : undefined

  return (
    <Box
      {...commonWordProps}
      color={color}
      opacity={prefs.showErrorsWhileTyping ? 1 : 0.6}
    >
      {wanted}
    </Box>
  )
}

type CurrentWordProps = { wanted: string; actual: string }
const CurrentWord = forwardRef<HTMLDivElement, CurrentWordProps>(
  ({ wanted, actual }, ref) => {
    const { prefs } = usePrefs()
    const bgColor = useColorModeValue("gray.200", "gray.600")
    const wrongBgColor = useColorModeValue("red.100", "red.800")

    const statusColor = wanted.startsWith(actual) ? bgColor : wrongBgColor

    return (
      <Box
        ref={ref}
        {...commonWordProps}
        bgColor={prefs.showErrorsWhileTyping ? statusColor : bgColor}
      >
        {wanted}
      </Box>
    )
  }
)

const WantedWord: FC = ({ children }) => (
  <Box {...commonWordProps}>{children}</Box>
)

const WordsWrapper = styled.div`
  overflow: hidden;
  font-size: 200%;
  font-weight: 500;
  line-height: 1.2em;
  height: calc((1.2em + ${commonWordProps.paddingX}) * 2);
`

const WordsWrapperInner = styled.div`
  position: relative; /* relative because Cursor is absolutely positioned */
  display: flex;
  flex-wrap: wrap;
  transition-timing-function: ease;
  transition-property: transform;
  transition-duration: 150ms;
`

export function Words({
  typedWords,
  activeWord,
  wantedWords,
  wantedWordI,
}: {
  typedWords: string[]
  activeWord: string
  wantedWords: string[]
  wantedWordI: number
}) {
  const wordsWrapperInnerRef = useRef<HTMLDivElement>(null)
  const activeWordRef = useRef<HTMLDivElement>(null)

  const restOfWantedWords = wantedWords.slice(wantedWordI)

  useEffect(() => {
    if (!wordsWrapperInnerRef.current) return
    wordsWrapperInnerRef.current.style.transform = `translateY(-${activeWordRef.current?.offsetTop}px)`
  })

  return (
    <WordsWrapper>
      <WordsWrapperInner ref={wordsWrapperInnerRef}>
        {typedWords.map((w, i) => (
          <TypedWord key={w + i} wanted={wantedWords[i]} actual={w} />
        ))}
        <CurrentWord
          ref={activeWordRef}
          wanted={head(restOfWantedWords) || ""}
          actual={activeWord}
        />
        {tail(restOfWantedWords).map((w, i) => (
          <WantedWord key={w + i}>{w}</WantedWord>
        ))}
      </WordsWrapperInner>
    </WordsWrapper>
  )
}
