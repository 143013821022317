import { Text } from "@chakra-ui/react"
import React from "react"
import { Authenticated2 } from "../components/auth/Authenticated2"
import { EnsureEmailVerifiedViaRedirect } from "../components/auth/VerifyEmail"
import { BigMessageSpinner } from "../components/BigMessageSpinner"
import { ErrorBoundary } from "../components/ErrorBoundary"
import { BodyWrapper } from "../components/layout/BodyWrapper"
import { Layout } from "../components/layout/Layout"
import { LoadingPage } from "../components/layout/LoadingPage"
import { Nav } from "../components/layout/Nav"
import { PageHeading } from "../components/layout/PageHeading"
import {
  defaultPrefsContextValue,
  PrefsProvider,
  usePrefs,
} from "../components/typing-test-2/PrefsContext"
import { TypingTest2 } from "../components/typing-test-2/TypingTest2"
import { LoggedOutHeader } from "../components/typing-test/LoggedOutHeader"
import { PageTitle } from "../components/typing-test/PageTitle"
import { TypingTest } from "../components/typing-test/TypingTest"
import { useFirestore2 } from "../useFirestore2"

function LoggedOut() {
  return (
    <Layout>
      <Nav showBottomBorder />
      <BodyWrapper>
        <LoggedOutHeader />
        {/* <div> to keep label text spaced together with typing test */}
        <div>
          <Text mb={2} opacity={0.6}>
            Try a typing session:
          </Text>
          <TypingTest2 />
        </div>
      </BodyWrapper>
    </Layout>
  )
}

function TypingTestWithPrefs() {
  const { saveResults } = useFirestore2()
  const { isLoading, prefs } = usePrefs()

  if (prefs === defaultPrefsContextValue.prefs && isLoading) {
    return <BigMessageSpinner />
  }

  switch (prefs.ui) {
    case "with-cursor":
      return <TypingTest2 onCompleteRun={saveResults} />
    case "word-by-word":
      return <TypingTest onCompleteRun={saveResults} />
    default:
      throw new Error(`\`prefs.ui\` has an invalid value: \`${prefs.ui}\``)
  }
}

function LoggedIn() {
  return (
    <Layout>
      <Nav showBottomBorder />
      {/* <Json>{prefs}</Json> */}
      <BodyWrapper>
        <EnsureEmailVerifiedViaRedirect />
        <PageHeading>Typing Test</PageHeading>
        <ErrorBoundary>
          <PrefsProvider>
            <TypingTestWithPrefs />
          </PrefsProvider>
        </ErrorBoundary>
      </BodyWrapper>
    </Layout>
  )
}

export default function Home() {
  return (
    <>
      <PageTitle />
      <Authenticated2
        loading={<LoadingPage />}
        auth={<LoggedIn />}
        noAuth={<LoggedOut />}
      />
    </>
  )
}
