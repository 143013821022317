import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Spacer,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React, { useEffect, useReducer, useRef } from "react"
import { RiSoundModuleLine } from "react-icons/ri"
import { RunData2 } from "../../types"
import { useReplayTimer } from "../../useReplayTimer"
import { randomWords } from "../../wordUtils"
import { useDebug } from "../debug/DebugContext"
import { Boxed } from "../typing-test-2/Boxed"
import { BoxedContent } from "../typing-test-2/BoxedContent"
import { usePrefs } from "../typing-test-2/PrefsContext"
import { PrefsPopover } from "../typing-test-2/PrefsPopover"
import {
  finalTypedWords,
  init,
  typingReducer,
} from "../typing-test-2/typingReducer"
import { useTypingKeys } from "../typing-test-2/useTypingKeys"
import { FancyTimer } from "./FancyTimer"
import Stats from "./Stats"
import { TimesUpBox } from "./TimesUpBox"
import { TimesUpMessage } from "./TimesUpMessage"
import { Words } from "./Words"

export function TypingTest({
  onCompleteRun,
}: {
  onCompleteRun?: (runData: RunData2) => void
}) {
  const { showDebug } = useDebug()
  const mixpanel = useMixpanel()
  const toast = useToast()
  const { prefs } = usePrefs()

  const { durationSeconds, vocabSize } = prefs

  const {
    startTimer,
    resetTimer,
    secondsPassed,
    isTimeUp,
    secondsLeft,
  } = useReplayTimer({ durationSeconds, onTimesUp })

  const inputRef = useRef<HTMLInputElement>(null)
  const [$, dispatch] = useReducer(
    typingReducer,
    init(randomWords({ vocabSize }))
  )
  const { handleReset, handleKeyDown } = useTypingKeys({ dispatch, startTimer })

  useEffect(() => {
    const ref = inputRef.current
    const id = window.setTimeout(() => ref?.focus(), 100)
    return () => window.clearTimeout(id)
  }, [])

  useEffect(() => {
    dispatch({
      type: "RESET",
      wantedWords: randomWords({ vocabSize }),
    })
  }, [vocabSize])

  function reset() {
    resetTimer()
    handleReset()
    setTimeout(() => inputRef.current?.focus(), 0)
  }

  function onTimesUp() {
    if (!onCompleteRun) return

    const typedWords = finalTypedWords($)

    if (showDebug) {
      toast({ title: `Saving... ${typedWords.length} words` })
    }

    try {
      mixpanel.track("run.complete")
      mixpanel.people.increment("run_total_seconds", durationSeconds)
    } catch {}

    onCompleteRun({
      wordsToType: $.wantedWords,
      typedWords,
      history: $.history,
      catalogItemId: `${durationSeconds}_SEC_TOP_${vocabSize}_RANDOM_WORDS`,
      durationSeconds,
    })
  }

  return (
    <>
      <VStack spacing={4} alignItems="stretch">
        <Boxed p={4} onClick={() => inputRef.current?.focus()}>
          <BoxedContent isFaded={isTimeUp}>
            <Words
              typedWords={$.typedWords}
              activeWord={$.activeWord}
              wantedWords={$.wantedWords}
              wantedWordI={$.wantedWordI}
            />
          </BoxedContent>
          <TimesUpMessage show={isTimeUp} />
        </Boxed>
        <Box m="-2px">
          <Box p="2px">
            <HStack spacing={3}>
              <Input
                size="lg"
                ref={inputRef}
                // variant="filled"
                // https://stackoverflow.com/a/5171812
                // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/Attributes.html#//apple_ref/doc/uid/TP40008058-autocapitalize
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                boxShadow="inset 0 1px 4px #00000022"
                style={{ width: "24ch" }}
                placeholder={isTimeUp ? "" : "Type here..."}
                value={$.activeWord}
                onKeyDown={e => {
                  // Prevent scrolling when pressing space
                  if (e.key === " ") e.preventDefault()
                  if (!isTimeUp) {
                    handleKeyDown(e)
                  }
                }}
              />
              <Button onClick={reset} size="lg">
                ↺ Reset
              </Button>
              <FancyTimer
                isStarted={secondsPassed > 0}
                seconds={durationSeconds}
                secondsLeft={secondsLeft}
              />
              <Spacer />
              <PrefsPopover>
                <Button
                  size="lg"
                  rounded="full"
                  leftIcon={
                    <Icon
                      as={RiSoundModuleLine}
                      transform="translateY(-.10em)"
                    />
                  }
                >
                  Preferences
                </Button>
              </PrefsPopover>
            </HStack>
          </Box>
        </Box>
        <Wrap spacing={4}>
          <WrapItem>
            <TimesUpBox show={isTimeUp}>
              <Stats
                durationSeconds={durationSeconds}
                wordsToType={$.wantedWords}
                typedWords={finalTypedWords($)}
              />
              <Button
                colorScheme="blue"
                onClick={reset}
                size="lg"
                boxShadow="md"
              >
                ↺ Go Again
              </Button>
            </TimesUpBox>
          </WrapItem>
        </Wrap>
      </VStack>
    </>
  )
}
