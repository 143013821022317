import firebase from "firebase/app"
import { History, RunData2 } from "./types"
import { useToast } from "@chakra-ui/react"
import { useAuthenticated } from "./components/auth/Authenticated"
import { stats } from "./wordUtils"
import { useDebug } from "./components/debug/DebugContext"

// About converters
// https://firebase.google.com/docs/reference/js/firebase.firestore.FirestoreDataConverter
const historyConverter = {
  toFirestore(history: History) {},
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): History {
    const data = snapshot.data(options)!
    return
  },
}

type FireHistory = {
  wordsToType: string[]
  typedWords: string[]
  history: string
  catalogItemId: string
  durationSeconds: number
  endTimestamp: number
}

export function useFirestore2() {
  const { showDebug } = useDebug()
  const toast = useToast()
  const { app, userDoc } = useAuthenticated()

  function saveResults(r: RunData2) {
    const ts = Date.now()

    // • Setup batch
    const batch = app.firestore().batch()

    // • Save histories
    const historiesRef = userDoc().collection("histories").doc()
    batch.set(historiesRef, {
      wordsToType: r.wordsToType,
      typedWords: r.typedWords,
      history: JSON.stringify(r.history),
      catalogItemId: r.catalogItemId,
      durationSeconds: r.durationSeconds,
      endTimestamp: ts,
    } as FireHistory)

    // • Save computed stats
    const theStats = stats({
      durationSeconds: r.durationSeconds,
      wantedWords: r.wordsToType,
      actualWords: r.typedWords,
    })
    // Use `historiesRef.id` so the two can be matched up
    const statsRef = userDoc().collection("stats").doc(historiesRef.id)
    batch.set(statsRef, {
      timestamp: firebase.firestore.Timestamp.fromDate(new Date(ts)),
      ...theStats,
    })

    return batch.commit().then(() => {
      if (showDebug) {
        toast({
          title: `Done saving data ${theStats.wpm} WPM, ${r.typedWords.length} words`,
          duration: 3000,
        })
      }
    })
  }

  return { saveResults }
}
